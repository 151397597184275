import axios, {
  AxiosRequestConfig,
  AxiosPromise,
  AxiosResponse,
  AxiosError,
} from 'axios'
import { Toast } from 'antd-mobile'
import qs from 'qs'
import ULRlIST from './urlList'

type UrlKeys = typeof ULRlIST
export type HttpUrlKey = keyof UrlKeys

// 基本配置
axios.defaults.timeout = 20000
axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded'
axios.defaults.baseURL = '/api'

// const mark = () => {
//   Toast.loading({
//     content: '加载中...',
//     duration: 0,
//     key: 'loading',
//     style: {
//       width: '100vw',
//       height: '100vh',
//       background: 'rgba(0, 0, 0, 0.2)',
//     },
//   })
//   const content = document.getElementsByClassName('ant-message')
//   const loadingContent = content[0] as HTMLElement
//   // 允许该元素成为事件的target
//   // loadingContent
//   // loadingContent.addEventListener("click", (e: Event) => {
//   //   e.stopPropagation()
//   // }, true)
//   loadingContent.style.pointerEvents = 'auto'
// }

// h1是否显示遮罩层
let mark = false

// 请求拦截
axios.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = localStorage.getItem('token')
  if (token) (config.headers as any)['Authorization'] = 'Bearer ' + token
  if (config.params?.h1) {
    delete config.params.h1
    mark = true
    Toast.loading('加载中...', 0, undefined, true)
  }
  return config
})

// 响应拦截
axios.interceptors.response.use(
  (res: AxiosResponse<any>) => {
    setTimeout(() => {
      if (mark) {
        // 防止新的接口请求关闭提示
        Toast.hide()
        mark = false
      }

      if (res?.data?.code !== 200) {
        res?.data?.msg && Toast.fail(res?.data?.msg)
      }
    }, 0)
    // Toast.hide()
    if (res?.data?.code !== 200) {
      res?.data?.msg && Toast.fail(res?.data?.msg)
    }
    return res
  },
  (err: AxiosError) => {
    setTimeout(() => {
      if (mark) {
        Toast.hide()
        mark = false
      }
      if (err?.message?.startsWith('timeout of')) {
        // 发起流程因超时错误
        Toast.fail('请求超时， 请重试')
      } else {
        Toast.fail('网络错误, 请重试')
      }
    }, 0)
    // return Promise.reject(err)
    return err
  }
)

// 配置axios
function initApiConfig<T extends object, K extends keyof T>(
  urlList: T,
  key: K
): T[K] {
  return urlList[key]
}

const Axios = {
  get: (key: HttpUrlKey, params?: AxiosRequestConfig): AxiosPromise<any> => {
    let url: string = initApiConfig(ULRlIST, key)
    if (params?.url) url = params.url
    return axios.get(url, params)
  },
  post: (
    key: HttpUrlKey,
    data?: AxiosRequestConfig,
    config?: AxiosRequestConfig
  ): AxiosPromise<any> => {
    const url: string = initApiConfig(ULRlIST, key)
    return axios.post(url, data, config)
  },
  postForm: (
    key: HttpUrlKey,
    data?: AxiosRequestConfig<any>,
    config?: AxiosRequestConfig<any>
  ): AxiosPromise<any> => {
    let { url, ...params } = data || {}
    if (!url) url = initApiConfig(ULRlIST, key)
    return axios.post(url, qs.stringify(params, { arrayFormat: 'repeat' }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      ...config,
    })
  },
  put: (
    key: HttpUrlKey,
    data?: AxiosRequestConfig,
    config?: AxiosRequestConfig
  ): AxiosPromise<any> => {
    const url: string = initApiConfig(ULRlIST, key)
    return axios.put(url, data, config)
  },
  delete: (key: HttpUrlKey, params?: AxiosRequestConfig): AxiosPromise<any> => {
    let url: string = initApiConfig(ULRlIST, key)
    if (params?.url) url = params.url
    return axios.delete(url, params)
  },
}

export default Axios

/*
// let params = [1, 2, 3];

// indices(默认)
qs.stringify({a: params}, {
  arrayFormat: 'indices'
})
// 结果是
'a[0]=1&a[1]=2&a[2]=3'

// brackets
qs.stringify({a: params}, {
  arrayFormat: 'brackets'
})
// 结果是
'a[]=1&a[]=2&a[]=3'

// repeat
qs.stringify({a: params}, {
  arrayFormat: 'repeat'
})
// 结果是
'a=1&a=2&a=3'
*/
