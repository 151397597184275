export const GET_TOKEN = 'getToken'
export const USERINFO = 'userinfo'
export const DICT_SUCCESS = 'dictSuccess'
export const DICT_FAIL = 'dictFail'
export const DICT_LOADING = 'dictLoading'
export const USER_CENTER = 'userCenter'
export const PUBLIC_STATE = 'publicState'
export const AUTH_SUCCESS = 'authSuccess'
export const AUTH_FAIL = 'authFail'
export const AUTH_LOADING = 'authLoading'
