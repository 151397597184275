import React, { useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import store from '@/store'
import { userAction } from '@/store/actionCreators'
const RouterGuards = (props: { component: React.FC }) => {
  const token = localStorage.getItem('token')
  const { userinfoReducer } = store.getState()
  useEffect(() => {
    if (!userinfoReducer.userinfo && token) {
      store.dispatch(userAction(token) as any)
    }
  }, [])
  if (
    (props as any)?.location?.pathname === '/main/message' &&
    (props as any)?.location?.search
  )
    return <Route path='/main/message' component={props.component} />
  if (token) {
    return <Route path='/main' component={props.component} />
  }
  return <Redirect to='/main/home' />
  // return <Redirect to='/main/index' />
}

export default RouterGuards
