import { Reducer, Action } from 'redux'
import { AUTH_SUCCESS, AUTH_LOADING, AUTH_FAIL } from '../actionTypes'
export interface AuthState {
  reportArr?: string[] // 财务统计权限
  menuFlag?: string // 二级部门周报权限 0，无权限；1，汇总列表权限；2，填写权限；3，汇总列表权限和填写权限"
  httpStatus?: 'loading' | 'fail' | 'success'
  permissions?: string[]
  // selectPersonList: API.PersonItem[]
}

export interface OptionAction extends Action<string> {
  value: AuthState
}

const authReducer: Reducer<AuthState, OptionAction> = (
  state: AuthState = {},
  action: OptionAction
) => {
  if (action.type === AUTH_SUCCESS) {
    const newState = {
      ...(action.value || {}),
      httpStatus: 'success' as AuthState['httpStatus'],
    }
    return newState
  }
  if (action.type === AUTH_FAIL) {
    const newState = {
      httpStatus: 'fail' as AuthState['httpStatus'],
    }
    return newState
  }
  if (action.type === AUTH_LOADING) {
    const newState = {
      httpStatus: 'loading' as AuthState['httpStatus'],
    }
    return newState
  }
  return state
}

export default authReducer
