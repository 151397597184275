import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import loginReducer, { State as loginState } from './loginReducer'
import userinfoReducer, { State as userState } from './userReducer'
import dictReducer, { State as dictState } from './dictReducer'
import userCenterReducer, { State as centerState } from './userCenterReducer'
import publicReducer, { PublicState } from './publicReducer'
import authReducer, { AuthState } from './authReducer'
import history from '../history'
let reducers = combineReducers({
  loginReducer,
  userinfoReducer,
  dictReducer,
  userCenterReducer,
  publicReducer,
  authReducer,
  router: connectRouter(history),
})
export default reducers

export interface RootState {
  loginReducer: loginState
  userinfoReducer: userState
  dictReducer: dictState
  userCenterReducer: centerState
  authReducer: AuthState
  publicReducer: PublicState
}
