const URLLIST = {
  // 登录相关
  login: '/auth/umeLogin ',
  userinfo: '/system/user/getInfo ',
  userList: '/system/dept/allocatedDeptListTemp',
  userListPage: '/system/employee/listNoDataScope',
  umeLoginCode: '/auth/umeLoginCode',
  // 上传图片
  uploadImg: '/file/file/upload',
  uploadPicture: '/file/file/uploadPicture',
  // 获取图片
  getFileUrl: '/file/file/getFileUrl',
  // 获取文件
  getFile: '/file/file/getFile',

  // 请假相关
  leaveList: '/system/leavehis/qjUserList', // system/leavehis/qjList
  leaveType: '/system/uservacation/userVacationList',
  leaveAdd: '/system/leavehis',
  leaveDate: '/system/leavehis/getWorkDay',
  // 请假统计
  qjtjList: '/system/leavehis/qjtjList',
  cancelLeave: '/system/leavehis/revocationProcess',
  // 出差相关
  evectionList: '/system/businesstravelHis/allUserList', // /system/businesstravelHis/allList
  cancelEvection: '/system/businesstravelHis/revocationProcess',
  businesstApplyadd: '/system/businesstravelHis',
  appCcList: '/system/businesstravelHis/appCcList',
  // 时间限制
  timeLimit: '/system/hrmTimePermissions/getUserPermissions',
  // 外出相关
  outList: '/system/gooutHis/userAllList', // /system/gooutHis/allList
  cancelOut: '/system/gooutHis/revocationProcess',
  outAdd: '/system/gooutHis',
  getWorkHours: '/system/gooutHis/getWorkHours',
  taskList: '/system/dingFlow/taskList',
  dictItem: '/system/dict/data/type/',
  deptList: '/system/gooutHis/userGooutList',
  approve: '/system/dingFlow/checkDingDing',
  appGooutList: '/system/gooutHis/appGooutList',
  // 外出培训
  trainingAdd: '/system/hrmOutsideTraining/add',
  trainingList: '/system/hrmOutsideTraining/userlist',
  trainingDetail: '/system/hrmOutsideTraining/',
  trainingCancel: '/system/hrmOutsideTraining/revocationProcess',
  hrmOutsideTraining: '/system/hrmOutsideTraining/judge', // 判断是钉钉还是crp
  // 待办
  backlogList: '/system/dingFlow/checkTaskList',
  // 电子券
  ticketList: '/system/ticket/oneTicketDetailList',
  ticketUseList: '/system/ticketattendanceuse/checkAppList',
  ticketSendList: '/system/ticketransferhis/list',
  ticketSendAdd: '/system/ticketUser/sendHrmTicketUser',
  userAppDetailList: '/system/ticketUser/userAppDetailList',
  userAppCdList: '/system/hrmTicketExchange/userAppCdList',
  hrmTicketExchange: '/system/hrmTicketExchange',
  hrmTicketExchangeCd: '/system/hrmTicketExchange/addCd',
  hrmTicketExchangeList: '/system/hrmTicketExchange/applist',
  hrmTicketExchangeDetail: '/system/hrmTicketExchange/',
  ownlist: 'system/hrmTicketDeductions/ownlist',

  // 总结管理
  summarizeTypeList: '/administration/summaryType/appList',
  summarizeTypeDetail: '/administration/summaryType/appDetail',
  summarizeList: '/administration/summaryDetail/appList',
  summarizeAdd: '/administration/summaryDetail/appAdd',
  summarizeEdit: '/administration/summaryDetail/appEdit',
  summarizeRemove: '/administration/summaryDetail/appRemove',
  summarizeExt: '/administration/summaryDetail/appExtList',
  summarizeExtDetail: '/administration/summaryDetail/appDetail',
  admSummarizeCc: '/administration/admSummaryCc',
  admSummarizeCcList: '/administration/admSummaryCc/list',
  admRead: '/administration/admRead/del',
  // 获取周总结部门list选项
  admSecondWeeklyDept: '/administration/admSecondWeeklyDept/ownList',
  hiddenType: '/administration/hiddenType/list',

  // 二级部门周报管理
  userAdmSecondWeekly: '/administration/AdmSecondWeekly/userAdmSecondWeekly',
  weeklyList: '/administration/AdmSecondWeekly/list',
  weeklyAdd: '/administration/AdmSecondWeekly',
  weeklyDel: '/administration/AdmSecondWeekly/del',
  weeklyDetail: '/administration/AdmSecondWeekly/del',
  weeklyCountType: '/administration/AdmSecondWeeklyStatistics/list',
  weeklyCountList: '/administration/AdmSecondWeeklyDetail/list',

  // 纪要管理
  summaryTypeList: '/administration/meetType/appList',
  summaryList: '/administration/meetSummaryDetail/appList',
  summaryDetail: '/administration/meetSummaryDetail/appDetail',
  summaryAdd: '/administration/meetSummaryDetail/appAdd',
  summaryEdit: '/administration/meetSummaryDetail/appEdit',
  summaryDel: '/administration/meetSummaryDetail/appRemove',

  // 财务
  getSsoToken: '/financial/thirdSystem/getSsoToken/',
  finReportLegal: '/financial/finReport/listLegal',
  finReportDept: '/financial/finReport/listDept',

  // 科研成果
  // 专利
  srGainPatentList: '/project/srGainPatent/list',
  srGainPatentDetail: '/project/srGainPatent/',
  // 软著
  srGainSoftList: '/project/srGainSoft/list',
  srGainSoftDetail: '/project/srGainSoft/',
  // 论文
  srGainPaperList: '/project/srGainPaper/list',
  srGainPaperDetail: '/project/srGainPaper/',
  // 标准
  srGainStandardList: '/project/srGainStandard/list',
  srGainStandardDetail: '/project/srGainStandard/',
  // 荣誉
  srGainHonorList: '/project/srGainHonor/list',
  srGainHonorDetail: '/project/srGainHonor/',
  // 字典
  srGainDict: '/project/srGainDict/list',

  // 督办任务
  superviseTaskList: '/administration/superviseTask/list',
  superviseTaskStart: '/administration/superviseTask/startTask',
  superviseTaskStop: '/administration/superviseTask/stopTask',
  superviseTaskEnd: '/administration/superviseTask/endTask',
  superviseTaskOver: '/administration/superviseTask/overTask',
  superviseTaskaddRate: '/administration/superviseTask/addRate',
  superviseTaskaddOver: '/administration/superviseTask/addOverSummary',
  superviseTaskaddInstructions: '/administration/superviseTask/addInstructions',
  superviseTaskDetail: '/administration/superviseTask/',
  superviseTaskFeedback: '/administration/admSuperviseTaskFeedback',
  superviseTaskFeedbackList: '/administration/admSuperviseTaskFeedback/list',

  // 代办、收到申请详情接口
  checkDingDetail: '/system/dingFlow/checkDingDetail',
  // 考勤
  everydayList: '/system/attendancerecord/everydayList',
  getAppMonth: '/system/attendancemonth/getAppMonth',
  errorNormalAdd: '/system/attendancerecord/dealAttendance',
  getAppMonthDetail: '/system/attendancemonth/getAppMonthDetail',

  // 项目管理
  projectList: '/project/itemBaseInfo/appList',
  projectDetail: '/project/itemBaseInfo/',
  projectPayList: '/project/itemSpendBudget/list',
  projectUserList: '/project/itemMember/list',
  projectFileList: '/project/itemFile/list',
  projectChangeList: '/project/itemChangeRecord/list',

  // 个人中心
  employee: '/system/employee/getInfoEmpOnApp', // system/employee
  employeeDept: '/system/employee/deptPost',
  contractList: '/system/contract/list',
  // 修改信息
  editUserinfo: 'system/employee/editOnApp',
  umeSendHis: '/system/umeSendHis',
  // 培训经历相关
  editTrain: '/system/trainingExperience',

  // 教育相关
  editEdu: '/system/studyInfo',

  // 工作相关
  editWork: '/system/workExperience',

  // 消息管理
  msgList: '/administration/notice/list',
  msgTypeList: '/administration/noticeType/list',
  msgDetail: '/administration/notice/',
  // 根据id获取url
  idToUrl: '/file/file/getFileUrls',
  crpStatus: '/system/sysCheckLife/getCrpInfo',

  // 西部学堂单点登录
  gotoClkj: 'system/logininfor/gotoClkj',
  // 资产清查单点登录
  gotoRFID: '/system/logininfor/gotoRfip',

  // 清单查询
  contractInventoryList: '/project/contractEndowments/list',
  contractInventoryDetail: '/project/contractEndowments/',
  businessInventoryList: '/project/reportEndowments/list',
  businessInventoryDetail: '/project/reportEndowments/',
}

export default URLLIST
