import React from 'react'
import ReactDOM from 'react-dom'
import 'moment/locale/zh-cn'
import App from './App'
import reportWebVitals from './reportWebVitals'
import 'antd-mobile/dist/antd-mobile.css'
import '@/assets/font/index.scss'
import './index.scss'
import 'quill/dist/quill.snow.css'
// 引入 fastclick

// 解决点击事件延迟300毫秒的问题
// fastclick.attach(document.body)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
