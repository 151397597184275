import { Reducer, Action } from 'redux'
import { PUBLIC_STATE } from '../actionTypes'
export interface PublicState {
  selectPersonList: API.PersonItem[]
  selectPersonList2?: API.PersonItem[]
  selectPersonList3?: API.PersonItem[]
}

export interface OptionAction extends Action<string> {
  value: {
    key: keyof PublicState
    data: any
  }
  key: keyof PublicState
}

const publicReducer: Reducer<PublicState, OptionAction> = (
  state: PublicState = { selectPersonList: [] },
  action: OptionAction
) => {
  if (action.type === PUBLIC_STATE) {
    let newState: PublicState = JSON.parse(JSON.stringify(state))
    newState[action.value.key] = action.value.data
    return newState
  }
  return state
}

export default publicReducer
