// import { OptionAction } from './loginReducer'
import { Reducer } from 'redux'
// import { createReducer } from './util'
import { DICT_LOADING, DICT_FAIL, DICT_SUCCESS } from '../actionTypes'

const initState: {
  [k: string]: {
    loading: boolean
    success: boolean
    list: { dictValue: string; dictLabel: string }[]
  }
} = {}

interface dictAction {
  type: string
  value: {
    path: string
    data: any
  }
}

export type State = typeof initState

// const commbineReducer = {
//   [DICT_SUCCESS]: (state: State, action: OptionAction) => {
//     if (action.type === DICT_SUCCESS) {
//       let newState = JSON.parse(JSON.stringify(state))
//       const { data, path } = action.value
//       console.log(action)
//       newState[path] = {
//         loading: false,
//         success: true,
//         list: data,
//       }
//       return state
//     }
//     return state
//   },
//   [DICT_LOADING]: (state: State, action: OptionAction) => {
//     if (action.type === DICT_LOADING) {
//       const { path } = action.value
//       console.log(path)
//       let newState = JSON.parse(JSON.stringify(state))
//       newState[path] = {
//         loading: true,
//         success: false,
//         list: [],
//       }
//       return newState
//     }
//     return state
//   },
//   [DICT_FAIL]: (state: State, action: OptionAction) => {
//     if (action.type === DICT_FAIL) {
//       const { path } = action.value
//       let newState = JSON.parse(JSON.stringify(state))
//       newState[path] = {
//         loading: false,
//         success: false,
//         list: [],
//       }
//       return newState
//     }
//     return state
//   },
// }

const dictReducer: Reducer<State, dictAction> = (
  state: State = initState,
  action: dictAction
) => {
  let newState = JSON.parse(JSON.stringify(state))
  if (action.type === DICT_SUCCESS) {
    const { data, path } = action.value
    newState[path] = {
      loading: false,
      success: true,
      list: data,
    }
    return newState
  }
  if (action.type === DICT_LOADING) {
    const { path } = action.value
    newState[path] = {
      loading: true,
      success: false,
      list: [],
    }
    return newState
  }
  if (action.type === DICT_FAIL) {
    const { path } = action.value
    newState[path] = {
      loading: false,
      success: false,
      list: [],
    }
    return newState
  }
  return state
}

// export const dictLoading: Reducer<State, OptionAction> = (
//   state: State = initState,
//   action: OptionAction
// ) => {
//   if (action.type === DICT_LOADING) {
//     const { path } = action.value
//     let newState = JSON.parse(JSON.stringify(state))
//     newState[path] = {
//       loading: true,
//       success: false,
//       list: [],
//     }
//     return newState
//   }
//   return state
// }

// export const dictFail: Reducer<State, OptionAction> = (
//   state: State = initState,
//   action: OptionAction
// ) => {
//   if (action.type === DICT_FAIL) {
//     const { path } = action.value
//     let newState = JSON.parse(JSON.stringify(state))
//     newState[path] = {
//       loading: false,
//       success: false,
//       list: [],
//     }
//     return newState
//   }
//   return state
// }
export default dictReducer
