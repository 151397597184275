import { OptionAction } from './reducers/loginReducer'
import { AxiosRequestConfig } from 'axios'
import { Dispatch } from 'redux'
import { push } from 'connected-react-router'
import { RootState } from '@/store/reducers'
import Axios from '../http'
import URLLIST from '@/http/urlList'
import {
  GET_TOKEN,
  USERINFO,
  DICT_SUCCESS,
  DICT_LOADING,
  DICT_FAIL,
  USER_CENTER,
  PUBLIC_STATE,
  AUTH_SUCCESS,
  AUTH_LOADING,
  AUTH_FAIL,
} from './actionTypes'
import { PublicState } from './reducers/publicReducer'
export interface DictType {
  dictLabel: string
  dictValue: string
}

export const getTokenAction = (data: string) => {
  return {
    type: GET_TOKEN,
    value: data,
  }
}

export const loginAction = (data: AxiosRequestConfig) => {
  return async (dispatch: Dispatch) => {
    await Axios.post('login', data).then((res) => {
      const { access_token } = res.data.data
      if (access_token) localStorage.setItem('token', access_token)
      const action: OptionAction = getTokenAction(access_token)
      // 存储到store中
      dispatch(action)

      // 路由跳转
      dispatch(push('/'))
    })
  }
}

export const userAction = (token: string) => {
  return async (dispatch: Dispatch) => {
    await Axios.get('userinfo', {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }).then((res) => {
      const action = {
        type: USERINFO,
        value: {
          ...(res?.data?.user || {}),
          jobNumber: res?.data?.employee?.jobNumber,
        },
      }
      dispatch(action)
    })
  }
}

export const dictAction = (path: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    const { dictReducer } = getState()
    const d = dictReducer[path]
    if (d && (d.loading || d.success)) return
    dispatch({
      type: DICT_LOADING,
      value: {
        path,
      },
    })
    await Axios.get('dictItem', {
      url: URLLIST['dictItem'] + path,
      // params: { dicCode: '车牌类型' },
    } as any).then((res) => {
      if (res?.data?.code === 200) {
        const { data } = res.data
        dispatch({
          type: DICT_SUCCESS,
          value: {
            path,
            data,
          },
        })
      } else {
        dispatch({
          type: DICT_FAIL,
          value: {
            path,
          },
        })
      }
    })
  }
}

export const userCenterAction = (data: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: USER_CENTER,
      value: {
        ...data,
      },
    })
  }
}

export const publicAction = (key: keyof PublicState, data: any) => {
  // 需要改变的key和value
  return (dispatch: Dispatch) => {
    dispatch({
      type: PUBLIC_STATE,
      value: {
        key,
        data,
      },
    })
  }
}

export const authAction = () => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const {
      authReducer: { httpStatus: d },
    } = getState()
    if (d && (d === 'loading' || d === 'success')) return
    dispatch({
      type: AUTH_LOADING,
    })
    const res = await Axios.get('userAdmSecondWeekly')
    if (res?.data?.code === 200) {
      dispatch({
        type: AUTH_SUCCESS,
        value: res?.data?.data || {},
      })
    } else {
      dispatch({
        type: AUTH_FAIL,
      })
    }
  }
}
