import { USERINFO } from '../actionTypes'
import { Reducer, Action } from 'redux'

export interface OptionAction extends Action<string> {
  value: any
}

export interface State {
  userinfo: { [key: string]: any } | null
}

const defaultState: State = {
  userinfo: null,
}

const userinfoReducer: Reducer<State, OptionAction> = (
  state: State = defaultState,
  action: OptionAction
) => {
  if (action.type === USERINFO) {
    let newState: State = JSON.parse(JSON.stringify(state))
    newState.userinfo = action.value
    return newState
  }
  return state
}

export default userinfoReducer
